require('./bootstrap');

window.Vue = require('vue');

import * as Vue from 'vue';
import { createApp } from 'vue';


import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';




import ListaCarreraComponent from './components/ListaCarreraComponent';
import FormularioPostulanteComponent from './components/FormularioPostulanteComponent';
import ListaRepostulacionComponent from './components/ListaRepostulacionComponent';
import ListaUsuariosComponent from './components/ListaUsuariosComponent';
import App from "./App.vue";


if(  document.querySelector("#backend-index-carrera") ){
  const app = createApp(ListaCarreraComponent ,'ListaCarreraComponent' ).mount('#backend-index-carrera');
}


if(  document.querySelector("#frontend-index") ){
  
  const mountE2 = document.querySelector("#frontend-index");
  const app2 = createApp(FormularioPostulanteComponent , { ...mountE2.dataset } ).use(VueSweetalert2).mount('#frontend-index');

}

if(  document.querySelector("#backend-index-repostulacion") ){
  const app3 = createApp( ListaRepostulacionComponent , 'ListaRepostulacionComponent'  ).mount('#backend-index-repostulacion');
}


if(  document.querySelector("#backend-index-usuarios") ){
  const app4 = createApp( ListaUsuariosComponent , 'ListaUsuariosComponent'  ).mount('#backend-index-usuarios');
}





