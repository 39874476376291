<template>

    <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"/>

    <div class="row justify-content-center" v-if="page == 0 && pace == 'PACE'">

        <div class="col-md-10">
            
            <div class="card">
                <div class="card-header b-persian-green text-white">
                    <h6>Selecciona la vía por la cuál quiere repostular</h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <br/>

                        <div class="col-md-8">
                            <span class="is-required">Vía de Ingreso</span>
                            <!-- <p style="white-space: pre-line;">{{ message }}</p> -->
                            <br>
                            <select
                                id="carrera"
                                v-model="postulacion.via_ingreso"
                                name="via_ingreso"
                                placeholder="Seleccione"
                                v-on:change="carrera = 0 ; filtrarCarrera()"
                                class="form-control"
                                >
                                <option value="R">CUPO P.A.E.S ( Regular )</option>
                                <option value="P">CUPO P.A.C.E</option>
                                
                            </select>

                            <!-- <p style="white-space: pre-line; color: crimson;">{{ error_simulacion }}</p> -->

                        </div>

                    </div>
                    <br/><br/><br>
                    
                </div>
                <div class="card-footer">

                    <div v-if="!!postulacion.via_ingreso" class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <!-- <button class="btn btn-primary me-md-2" type="button">Button</button> -->
                        <div>
                            <button class="btn btn-persian-green me-md-2" v-on:click="page = 1; " > Continuar <font-awesome-icon :icon="['fas', 'arrow-right']" class="icon alt"/></button>
                        </div>

                    </div>


                </div>


            </div>
        </div>

    </div>

    <div class="row justify-content-center" v-if="page == 1">

        <div class="col-md-10">
            
            <div class="card">
                <div class="card-header b-persian-green text-white">
                    <h6>Selecciona la carrera a repostular</h6>
                </div>
                <div class="card-body">
                    <div class="row">

                        <div class="col-md-8">
                            <span class="is-required">Carrera</span>
                            <!-- <p style="white-space: pre-line;">{{ message }}</p> -->
                            <br>
                            <select
                                id="carrera"
                                v-model="carrera"
                                name="carrera"
                                placeholder="Seleccione"
                                v-on:change="simular()"
                                class="form-control"
                                >
                                <option v-for="option in carreras" v-bind:value="option.cod_demre">
                                   {{option.cod_demre}} - {{ option.carrera }}
                                </option>
                            </select>

                            <!-- <p style="white-space: pre-line; color: crimson;">{{ error_simulacion }}</p> -->

                        </div>

                    </div>
                    
                </div>


            </div>
        </div>
        
    </div>

    

    <br v-if="page == 1">
    <div class="row justify-content-center" v-if="page == 1">
        
        <div class="col-md-10">

            <div class="card">
                <div class="card-header b-persian-green text-white">
                    <h6  v-if="simulacion.ponderado != '-' && !!carrera" >Tu Puntaje :  <b>{{ simulacion.carrera.carrera  }}</b> </h6>
                </div>

                <div v-if="simulacion.ponderado != '-' && !!carrera "  class="card-body">
                    <div class="row">

                        <div class="col-md-12" style="align-items: center;">

                            <div class="table-responsive-md">
                                    
                                <!-- First Table -->
                                <table class="table table-hover">
                                    <thead class="table-success">
                                    <tr>
                                        <th style="text-align: center;">Puntaje Ponderado</th>
                                        <th style="text-align: center;">Ptje. Promedio C.Lec. y C.Matem. 1</th>
                                        <th style="text-align: center;">Vía de Ingreso</th>
                                        <th style="text-align: center;">Año Admisión</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style="text-align: center;">
                                        <b :class="{ 'b-success': simulacion.ponderado >= simulacion.carrera.ponderado_minimo, 'b-error': simulacion.ponderado < simulacion.carrera.ponderado_minimo }">{{ simulacion.ponderado }}</b>
                                        </td>
                                        <td style="text-align: center;">
                                        <b :class="{ 'b-success': simulacion.promedio_cm_max >= simulacion.carrera.promedio_minimo, 'b-error': simulacion.promedio_cm_max < simulacion.carrera.promedio_minimo }">{{ simulacion.promedio_cm_max }}</b>
                                        </td>
                                        <td style="text-align: center;">
                                        <b v-if="postulacion.via_ingreso === 'P'">P.A.C.E</b>
                                        <b v-if="postulacion.via_ingreso === 'R'">P.A.E.S (Regular)</b>
                                        </td>
                                        <td style="text-align: center;">
                                        <b>{{ persona.anyo_proceso }}</b>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <!-- Second Table -->
                                <table class="table table-hover">
                                    <thead class="table-success">
                                    <tr>
                                        <th style="text-align: center;">Puntaje Pond. Mínimo</th>
                                        <th style="text-align: center;">Promedio Mínimo C.Lec. y C.Mat.</th>
                                        <th style="text-align: center;">Porc. Sup Notas</th>
                                        <th style="text-align: center;">Permitid@ postular</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style="text-align: center;">{{ simulacion.carrera.ponderado_minimo }}</td>
                                        <td style="text-align: center;">{{ simulacion.carrera.promedio_minimo }}</td>
                                        <td style="text-align: center;">
                                        <b>{{ simulacion.porc_sup_notas }}</b>
                                        </td>
                                        <td style="text-align: center;">
                                        <b :class="{ 'b-success': simulacion.habilita_postular === 1, 'b-error': simulacion.habilita_postular !== 1 }">{{ simulacion.habilita_postular === 1 ? 'SÍ' : 'NO' }}</b>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                

                            </div>

                        </div>
                       
                    </div>
                    
                </div>

                <div class="card-footer">
                   
                    <div class="d-grid gap-2 d-md-flex" :class="pace == 'PACE' ? 'justify-content-between' : 'justify-content-md-end' ">
                        <button v-if="page == 1 && pace == 'PACE'" class="btn btn-persian-green me-md-2" v-on:click="page = 0;"><font-awesome-icon :icon="['fas', 'arrow-left']" class="icon alt"/> Anterior</button>
                        <div v-if="simulacion.habilita_postular == 1 && !!carrera">
                            <button class="btn btn-persian-green me-md-2" v-on:click="page = 2;" > Continuar <font-awesome-icon :icon="['fas', 'arrow-right']" class="icon alt"/></button>
                        </div>
                        <div v-else>
                            <button class="btn btn-persian-green me-md-2 disabled" v-on:click="page = 2;" > Continuar <font-awesome-icon :icon="['fas', 'arrow-right']" class="icon alt"/></button>
                        </div>
                    </div>

                    
                </div>


            </div>
        </div>
        
    </div>


    <div class="row justify-content-center" v-if="page == 2">

        <div class="col-md-10">

            <div class="card">

                <div class="card-header b-persian-green text-white">
                    <h6>Completa tus datos personales y de contacto</h6>
                </div>

                
                <div class="card-body">

                    <p v-if="errors.length">
                        <b>Favor , corrige la siguiente información:</b>
                        <ul>
                        <li v-for="error in errors"><span style="color:crimson;">{{ error }}</span></li>
                        </ul>
                    </p>


                    <p>Información Personal:</p>
                    <div class="row">

                        <div class="col-md-4">
                            <p>
                                <label for="nombres" class="is-required">Nombres</label>
                                <input
                                id="nombres"
                                v-model="persona.nombres"
                                type="text"
                                name="nombres"
                                class="form-control"
                                >
                            </p>
                        </div>

                        <div class="col-md-4">
                            <p>
                                <label for="primer_apellido" class="is-required">Primer Apellido</label>
                                <input
                                id="primer_apellido"
                                v-model="persona.primer_apellido"
                                type="text"
                                name="primer_apellido"
                                class="form-control"
                                >
                            </p>
                        </div>
                        
                        <div class="col-md-4">
                            <p>
                                <label for="segundo_apellido">Segundo Apellido</label>
                                <input
                                id="segundo_apellido"
                                v-model="persona.segundo_apellido"
                                type="text"
                                name="segundo_apellido"
                                class="form-control"
                                >
                            </p>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-md-3">
                            
                            <p>
                                <label for="fecha_nacimiento" class="is-required">Fecha de Nacimiento</label>

                                <Datepicker 
                                    v-model="persona.fecha_nacimiento"
                                    :textInputOptions="dateInputOptions"
                                    :enableTimePicker="false"
                                    modelType="yyyy-MM-dd"
                                    :format="format"
                                    :previewFormat="previewformat"
                                    locale="es" cancelText="Cancelar" selectText="Seleccionar"
                                >
                            
                                </Datepicker>
                                
                            </p>
                        </div>

                        <div class="col-md-3">
                            <p>
                                <label for="cod_sexo" class="is-required">Sexo</label>
                                <select
                                    id="cod_sexo"
                                    v-model="persona.cod_sexo"
                                    name="cod_sexo"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    >
                                    <option value="2">Femenino</option>
                                    <option value="1">Masculino</option>
                                </select>
                                
                            </p>
                        </div>

                        <!-- <div class="col-md-3">
                            <p>
                                <label for="cod_genero" class="is-required">Género</label>
                                <select
                                    id="cod_genero"
                                    v-model="persona.cod_genero"
                                    name="cod_sexo"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    >
                                    <option value="2">Femenino</option>
                                    <option value="1">Masculino</option>
                                    <option value="3">Trans</option>
                                    <option value="4">Género Diverso</option>
                                    <option value="5">Prefiero no responder</option>
                                </select>
                                
                            </p>
                        </div> -->

                    </div>

                    <div class="row">

                        <div class="col-md-3">
                            <p>
                                <label for="cod_nacionalidad" class="is-required">Nacionalidad</label>
                                <select
                                    id="cod_nacionalidad"
                                    v-model="persona.cod_nacionalidad"
                                    name="cod_nacionalidad"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    
                                    
                                    >
                                    <option value="1">Chilena</option>
                                    <option value="2">Extranjera</option>
                                </select>
                            </p>
                        </div>

                        <div class="col-md-3">
                            <p>
                                <label for="tipo_residencia" class="is-required">Tipo de Residencia</label>
                                <select
                                    id="tipo_residencia"
                                    v-model="persona.tipo_residencia"
                                    name="tipo_residencia"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    
                                    >
                                    <option value="0" disabled>Seleccione</option>
                                    <option value="1">Con Residencia Previa</option>
                                    <option value="2">Sin Residencia Previa</option>
                                    <option value="3">No Reside en Chile</option>
                                </select>
                            </p>
                        </div>                        


                        <div class="col-md-3">
                            <p>
                                <label for="pais_origen" class="is-required">País de Origen</label>
                                <select
                                    id="pais_origen"
                                    v-model="persona.pais_origen"
                                    name="pais_origen"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    
                                    >
                                    <option v-for="option in paises" v-bind:value="option.id">
                                        {{option.name}}
                                    </option>
                                    
                                </select>
                                
                            </p>
                        </div>

                        <div class="col-md-3">
                            <p>
                                <label for="pais_est_sec" class="is-required">De Últimos Estudios Secundarios</label>
                                <select
                                    id="pais_est_sec"
                                    v-model="persona.pais_est_sec"
                                    name="pais_est_sec"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    
                                    >
                                    <option v-for="option in paises" v-bind:value="option.id">
                                        {{option.name}}
                                    </option>
                                    
                                </select>
                                
                            </p>
                        </div>




                    </div>


                    <br>
                    <p>Dirección:</p>

                    <div class="row">

                        <div class="col-md-5">
                            <p>
                                <label for="calle_d" class="is-required">Calle/Avenida</label>
                                <input
                                id="calle_d"
                                v-model="persona.calle_d"
                                type="text"
                                name="calle_d"
                                class="form-control"
                                >
                            </p>
                        </div>

                        <div class="col-md-2" >
                            <p>
                                <label for="numero_d" class="is-required">Número</label>
                                <input
                                id="numero_d"
                                v-model="persona.numero_d"
                                type="text"
                                name="numero_d"
                                class="form-control"
                                >
                            </p>
                        </div>

                        <div class="col-md-2">
                            <p>
                                <label for="block_d">Block</label>
                                <input
                                id="block_d"
                                v-model="persona.block_d"
                                type="text"
                                name="block_d"
                                class="form-control"
                                >
                            </p>
                        </div>

                        <div class="col-md-2">
                            <p>
                                <label for="depto_d">Depto.</label>
                                <input
                                id="depto_d"
                                v-model="persona.depto_d"
                                type="text"
                                name="depto_d"
                                class="form-control"
                                >
                            </p>
                        </div>

                    </div>


                    <div class="row">

                        <div class="col-md-5">
                            <p>
                                <label for="sector_d">Villa / Población / Sector</label>
                                <input
                                id="sector_d"
                                v-model="persona.sector_d"
                                type="text"
                                name="sector_d"
                                class="form-control"
                                >
                            </p>
                        </div>

                        <div class="col-md-3">
                            <p>
                                <label for="region_d" class="is-required">Región</label>
                                <select
                                    id="region_d"
                                    v-model="persona.codigo_region_d"
                                    name="region_d"
                                    @change="fetchProvincia()"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    v-on:loadeddata="regiones"
                                    >
                                    <!-- <option v-for="(value, key, index) in regiones" :key="index" v-bind:value="key">
                                        {{value}}
                                    </option> -->
                                    <option v-for="option in regiones" v-bind:value="option.id">
                                        {{option.name}}
                                    </option>
                                    
                                </select>
                            </p>
                        </div>

                        <div class="col-md-3">
                            <p>
                                <label for="provincia_d" class="is-required">Provincia</label>
                                <select
                                    id="provincia_d"
                                    v-model="persona.codigo_provincia_d"
                                    name="provincia_d"
                                    @change="fetchComuna()"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    >
                                    <option v-for="option in provincias" v-bind:value="option.id">
                                        {{option.name}}
                                    </option>
                                    
                                </select>
                                
                            </p>
                        </div>

                        <div class="col-md-5">
                            <p>
                                <label for="comuna_d" class="is-required">Comuna/Ciudad</label>

                                <!-- <Select2 v-model="persona.codigo_comuna_d" class="form-control" :options="comunas" :search ="methodToUpdateComunas"   /> -->
                                
                                <select
                                    id="comuna_d"
                                    v-model="persona.codigo_comuna_d"
                                    name="comuna_d"
                                    placeholder="Seleccione"
                                    class="form-control"
                                    >
                                    <option v-for="option in comunas" v-bind:value="option.id">
                                        {{option.name}}
                                    </option>
                                    
                                </select>
                            </p>
                        </div>

                    </div> 
                    
                    
                    <p>Información de Contacto:</p>

                    <div class="row">

                        <div class="col-md-4">
                            <p>
                                <label for="email" class="is-required">Email</label>
                                <input
                                id="email"
                                v-model="persona.email"
                                type="email"
                                name="email"
                                class="form-control"
                                >
                            </p>
                        </div>

                        <div class="col-md-3">
                            <p>
                                <label for="name" class="is-required">Teléfono Móvil</label>
                                <input
                                id="numero_de_celular"
                                v-model="persona.numero_de_celular"
                                maxlength="9"
                                minlength="9"
                                type="text"
                                name="numero_de_celular"
                                class="form-control"
                                >
                            </p>
                        </div>
                        
                        <div class="col-md-3">
                            <p>
                                <label for="name">Teléfono Fijo</label>
                                <input
                                id="nro_de_telefono"
                                v-model="persona.nro_de_telefono"
                                maxlength="9"
                                minlength="9"
                                type="text"
                                name="nro_de_telefono"
                                class="form-control"
                                >
                            </p>
                        </div>

                    </div>

                </div>

                <div class="card-footer">

                    <div class="d-grid gap-2 d-md-flex d-flex justify-content-between">
                        <button class="btn btn-persian-green me-md-2" v-on:click="page = 1;"><font-awesome-icon :icon="['fas', 'arrow-left']" class="icon alt"/> Anterior</button>
                        <!-- <button class="btn btn-persian-green me-md-2" v-on:click="page = 0;"><font-awesome-icon :icon="['fas', 'arrow-left']" class="icon alt"/> Anterior</button> -->

                        <button class="btn btn-persian-green me-md-2" v-on:click="guardarPostulacion" ><font-awesome-icon :icon="['fas', 'floppy-disk']" class="icon alt"/> Guardar Datos </button>
                        <button class="btn btn-orange me-md-2" v-on:click="enviarPostulacion" >Enviar y Finalizar <font-awesome-icon :icon="['fas', 'check-square']" class="icon alt"/> </button>
                    </div>

                </div>

                
            </div>

        </div>

    </div>



    <br v-if="page == 2">
    <div class="row justify-content-center" v-if="page == 3">
        
        <div class="col-md-10">

            <div class="card">
                <div class="card-header b-persian-green text-white">
                    <h6>Tu Repostulación :  <b>{{ simulacion.carrera.carrera  }}</b> </h6>
                </div>


                <div class="card-body">
                    <div class="row">

                        <div class="col-md-12" style="align-items: center;">

                            <div class="table-responsive-md">
                                <table class="table table-hover">
                                
                                    <thead class="table-dark">
                                        <td style="text-align: left;" scope="6">Postulación</td>

                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td style="text-align: left;">RUN/Pasaporte</td>
                                            <td v-if="postulacion.tdoc == 'C'" style="text-align: left;">: {{persona.ndoc +  '-' + persona.dvdoc  }}</td>
                                            <td v-if="postulacion.tdoc == 'P'" style="text-align: left;">: {{persona.ndoc }}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: left;">Nombre Completo</td>
                                            <td style="text-align: left;">: {{persona.nombres + ' ' + persona.primer_apellido  + (!!persona.segundo_apellido ? ' ' + persona.segundo_apellido : '' ) }}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: left;">Carrera</td>
                                            <td style="text-align: left;">: {{postulacion.cod_carrera + ' - ' + simulacion.carrera.carrera}}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: left;">Vía de Postulación</td>
                                            <td v-if="postulacion.via_ingreso == 'R'" style="text-align: left;">: P.A.E.S (Regular)</td>
                                            <td v-if="postulacion.via_ingreso == 'P'" style="text-align: left;">: P.A.C.E</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: left;">Periodo Admisión</td>
                                            <td style="text-align: left;">: {{simulacion.anyo_proceso ?? persona.anyo_proceso}}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: left;">Puntaje Ponderado</td>
                                            <td style="text-align: left;">: <b>{{simulacion.ponderado}}</b></td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: left;">Promedio C.Lectora y C.Matemáticas 1</td>
                                            <td style="text-align: left;">: <b>{{simulacion.promedio_cm_max}}</b></td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: left;">Fecha de Postulación</td>
                                            <td style="text-align: left;">: {{ formatDate(postulacion.updated_at)}}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: left;">Resultado Postulación</td>
                                            
                                            <td style="text-align: left;" v-if="postulacion.estado == 'G'">:  Enviada  </td>
                                            <td style="text-align: left;" v-if="postulacion.estado == 'A'">:  Aprobada </td>
                                            <td style="text-align: left;" v-if="postulacion.estado == 'R'">:  Reprobada</td>
                                            
                        
                                        </tr>
  
                                    </tbody>
                                
                                </table>

                                <table class="table table-hover">
                                
                                    <thead class="table-success">
                                        <td style="text-align: left;" scope="2">Contacto</td>
                                        
 
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="text-align: left;">Teléfono</td>
                                            <td style="text-align: left;">: {{persona.numero_de_celular }} {{ ' ' +  ( persona.nro_de_telefono != null) ? persona.nro_de_telefono : ''     }}</td>
                                        
                                        </tr>

                                        <tr>
                                            <td style="text-align: left;">Email</td>
                                            <td style="text-align: left;">: {{persona.email}}</td>

                                        </tr>


                                    </tbody>

                                </table>

                            </div>

                        </div>
                       
                    </div>
                    
                </div>


            </div>
        </div>
        
    </div>


</template>


    

<script>
           
    import axios from 'axios'

    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    import VSwitch from 'v-switch-case'

    /* import the fontawesome core */
    import { library } from '@fortawesome/fontawesome-svg-core'

    /* import font awesome icon component */
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    import { faCheckSquare , faFloppyDisk, faArrowLeft , faArrowRight } from '@fortawesome/free-solid-svg-icons'
    library.add( faCheckSquare )
    library.add( faArrowLeft )
    library.add( faFloppyDisk)
    library.add( faArrowRight )

    import { ref } from 'vue';

    const api_url = process.env.MIX_API_URL;
    const api_externa_url = process.env.MIX_API_EXTERNA_URL;

    const date = ref(new Date());
    
    
    const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
     
    }

    const previewformat = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `Fecha seleccionada: ${day}/${month}/${year}`;
        }
    

   
    export default {
        
        components:{
            'font-awesome-icon' : FontAwesomeIcon ,
            Datepicker, VSwitch , Loading
            
            
            
        },
        props:{
            username: String ,
            documento : String,
            pace : String

        },

        setup(){
            

        },

        data() {

            
            return {

                date,
                //format,
                previewformat,

                format: 'yyyy-MM-dd',
                previewFormat: 'd/M/yyyy',

                isLoading: false,
                fullPage: true,

                page : ( this.$props.pace == 'PACE') ? 0 : 1,
                errors: [],
                error_simulacion : null,
                carrera:null ,
                carrera_usach: null,
                carr_elegida : null ,
                
                simulacion: {
                    /* ponderado_minimo : '-',
                    promedio_minimo : '-', */
                    ptje_ult_matri: '-',
                    habilita_postular:'-',
                    ponderado:'-',
                    promedio_cm_max :'-',
                    percentil_lm: '-',
                    anyo_proceso: '-',
                    carrera: {
                        career_id: null,
                        ponderado_minimo : null,
                        promedio_minimo: null

                    }
                },
                postulacion: {
                    tdoc: null, 
                    ndoc : null ,
                    dvdoc: null , 
                    cod_carrera: null ,
                    cod_usach: null,
                    via_ingreso: ( this.$props.pace == 'PACE') ? 'P' : 'R',
                    estado: null ,
                    created_at: null ,
                    updated_at : null


                },
                persona: {
                
                    tdoc:   null,
                    ndoc :  null,
                    dvdoc : null,
                    primer_apellido    : null,
                    segundo_apellido   : null,
                    nombres            : null,
                    cod_nacionalidad   : 1,
                    cod_sexo           : 2,
                    //cod_genero         : 2,
                    fecha_nacimiento   : date,
                    // Domicilio 
                    calle_d            : null,
                    numero_d           : null,
                    block_d            : null,
                    depto_d            : null,
                    sector_d           : null,
                    codigo_region_d    : null,
                    codigo_provincia_d : null,
                    codigo_comuna_d    : null,

                    nro_de_telefono    : null,
                    numero_de_celular  : null,
                    email              : null,
                    tipo_residencia    : 1,
                    pais_origen        : 1,
                    pais_est_sec       : 1

                } ,
                carreras : [],
                carrerasReg : [],
                carrerasPace: [],
                comunas: [],
                provincias : [] ,
                regiones : [],
                paises   : [],

                antecedentes : [],

                dateInputOptions : ref({
                    format: 'yyyy-MM-dd'
                })
                
               
            }
        },
        methods: {

            
            simular(){

                var credentials = window.btoa("visitor:NRKmM2SDf0");
                const currentObj = this;

                const swalWithBootstrapButtons = currentObj.$swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-persian-green',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                });

                const headers = {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                }
               
                currentObj.isLoading = true;
                axios
                .post(   api_url + "/api/postula/consultapuntaje", 
                
                {
                    "cod_carrera" : this.carrera ,
                    "via_ingreso" : this.postulacion.via_ingreso,
                    "username" : this.$props.username			
                },	
                {headers}			
                )
                .then(function (response) {

                    if( response.data.errors ){
                        
                        currentObj.error_simulacion = response.data.errors;
                        currentObj.carr_elegida = '';
                        currentObj.simulacion =  {
                            ponderado_minimo : '-',
                            promedio_minimo : '-',
                            ptje_ult_matri: '-',
                            habilita_postular:'-',
                            ponderado:'-',
                            promedio_cm_max :'-',
                            percentil_lm: '-',
                            anyo_proceso: '-'
                        },
                        currentObj.simulacion.carrera = {

                            career_id: null,
                            ponderado_minimo : null,
                            promedio_minimo: null

                        },

                        swalWithBootstrapButtons.fire({
                            icon: 'error',
                            title: 'Atención',
                            text: response.data.errors,
                            /* showConfirmButton: false,
                            timer: 2500, */
                            width: 800,
                            padding: '3em',                            

                            })

                    }else{

                        
                        currentObj.simulacion = response.data.simulacion;
                        //currentObj.simulacion.anyo_proceso = response.data.persona.anyo_proceso;
                        currentObj.simulacion.carrera = response.data.carrera;

                        currentObj.persona.tdoc = response.data.persona.tipo_identificacion;
                        currentObj.persona.ndoc = response.data.persona.numero_documento;
                        currentObj.persona.dvdoc = response.data.persona.dv;
                        currentObj.persona.anyo_proceso  = response.data.persona.anyo_proceso;

                        if( response.data.is_postulante == 'false'  ){

                            
                            currentObj.persona.calle_d            = response.data.persona.calle;
                            currentObj.persona.numero_d           = response.data.persona.numero;
                            currentObj.persona.block_d            = response.data.persona.block;
                            currentObj.persona.depto_d            = response.data.persona.departamento;
                            currentObj.persona.sector_d           = response.data.persona.villa_poblacion;
                            currentObj.persona.codigo_region_d    = response.data.persona.codigo_region;
                            currentObj.persona.codigo_provincia_d = response.data.persona.codigo_provincia;
                            currentObj.persona.codigo_comuna_d    = response.data.persona.codigo_comuna;
                            currentObj.persona.nro_de_telefono    = response.data.persona.telefono_fijo;
                            currentObj.persona.numero_de_celular  = response.data.persona.telefono_celular;
                            currentObj.persona.anyo_proceso  = response.data.persona.anyo_proceso;

                        }

                        if( currentObj.simulacion.habilita_postular == 1 ){

                            swalWithBootstrapButtons.fire({
                            icon: 'success',
                            title: 'Postulación habilitada',
                            text: 'Usted está habilitado para postular en la carrera seleccionada de nuestra universidad , para continuar haga click en "Continuar"!',
                            /* showConfirmButton: false,
                            timer: 2500, */
                            width: 800,
                            padding: '3em',
                           
                            })
                        }else{

                            swalWithBootstrapButtons.fire({
                            icon: 'error',
                            title: 'Atención',
                            text: response.data.errors,
                            /* showConfirmButton: false,
                            timer: 2500, */
                            width: 800,
                            padding: '3em',                            

                            })

                        }

                    
                        currentObj.fetchRegion();

                    }

                    currentObj.isLoading = false;
                    
        
                })
                .catch(function (error) {
                    currentObj.isLoading = false;
                    alert( "error en la operación : " + error  );
                });

                


            },

            checkForm: function () {
                this.errors = [];

                if (!this.persona.nombres) {
                    this.errors.push("Nombres son requeridos.");
                }
                if (!this.persona.primer_apellido) {
                    this.errors.push("Primer Apellido requerido.");
                }
                if ( this.persona.fecha_nacimiento == null || this.persona.fecha_nacimiento == ''  || !this.persona.fecha_nacimiento  ) {
                    this.errors.push("Se requiere ingresar su fecha de nacimiento.");
                }
                if (!this.persona.cod_sexo) {
                    this.errors.push("Seleccione si es hombre o mujer.");
                }
                /* if (!this.persona.cod_genero) {
                    this.errors.push("Seleccione su identidad de género.");
                } */
                if (!this.persona.cod_nacionalidad) {
                    this.errors.push("Seleccione su nacionalidad.");
                }
                if (!this.persona.tipo_residencia) { // een caso de ser extranjero 
                    this.errors.push("Se requiere ingresar el estado de residencia.");
                }
                if (!this.persona.pais_origen) { // een caso de ser extranjero 
                    this.errors.push("Se requiere ingresar su país de origen.");
                }
                if (!this.persona.pais_est_sec ) { // een caso de ser extranjero 
                    this.errors.push("Se requiere ingresar el país donde cursó sus últimos estudios secundarios.");
                }
                if (!this.persona.calle_d) {
                    this.errors.push("Se requiere ingresar la calle de la dirección de su domicilio.");
                }
                if (!this.persona.numero_d) {
                    this.errors.push("Se requiere ingresar el numero de su domicilio.");
                }
                if (!this.persona.codigo_comuna_d) {
                    this.errors.push("Se requiere ingresar la comuna de su domicilio.");
                }
                if (!this.persona.codigo_region_d) {
                    this.errors.push("Se requiere ingresar la región de su domicilio.");
                }
                if (!this.persona.codigo_provincia_d) {
                    this.errors.push("Se requiere ingresar la provincia de su domicilio.");
                }
                if (!this.persona.numero_de_celular || this.persona.numero_de_celular.length < 9) {
                    this.errors.push("Se requiere ingresar un número de celular de contacto o que contenga a lo menos 9 caracteres.");
                }
                if (!this.persona.email) {
                    this.errors.push('Email requerido.');
                } else if (!this.validEmail(this.persona.email)) {
                    this.errors.push('Ingrese un Email válido.');
                }


                if (this.errors.length) {
                    
                    return false;
                }


                return true;


                
            },

            validEmail: function (email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);

            },


            guardarPostulacion(){

                if( !this.checkForm()){

                    return false;
                }

                const currentObj = this;

                var postulacion = {

                    cod_carrera: currentObj.simulacion.carrera.cod_demre,
                    cod_usach: currentObj.simulacion.carrera.cod_usach,
                    estado : 'P', //Pendiente
                    via_ingreso: this.postulacion.via_ingreso

                };

                var persona = currentObj.persona;

                var credentials = window.btoa("visitor:NRKmM2SDf0");
                

                const headers = {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                };

                let params = {
                    postulacion : postulacion,
                    persona : persona
                    
                };

                currentObj.isLoading = true;

                axios
                .post(  api_url +  "/api/postula/guardarPostulacion", 
                params
                ,	
                {headers}			
                )
                .then(function (response) {

                    currentObj.isLoading = false; 

                    const swalWithBootstrapButtons = currentObj.$swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-persian-green',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    });

                    if( response.data.errors ){
                        
                        swalWithBootstrapButtons.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.errors,
                           
                        })
                       

                    }else{

                        swalWithBootstrapButtons.fire({
                            icon: 'success',
                            title: 'Excelente',
                            text: 'Has guardado los datos de tu postulación. Para hacer efectiva tu postulación debes Enviar y Finalizar. ',
                           
                        })
                    }
                    
        
                })
                .catch(function (error) {
                    alert( "error en la operación : " + error  );
                    currentObj.isLoading = false;
                });

                        



            },


            enviarPostulacion(){

                if( !this.checkForm()){

                    return false;
                }

                const currentObj = this;

                var postulacion = {

                    cod_carrera: currentObj.simulacion.carrera.cod_demre,
                    cod_usach: currentObj.simulacion.carrera.cod_usach,
                    via_ingreso: this.postulacion.via_ingreso,
                    estado : 'G' //Guardado

                };

                var persona = currentObj.persona;

                var credentials = window.btoa("visitor:NRKmM2SDf0");


                const headers = {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                };

                let params = {
                    postulacion : postulacion,
                    persona : persona
                    
                };


                // swal 2 decision 

                const swalWithBootstrapButtons = currentObj.$swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-persian-green',
                        cancelButton: 'btn btn-secondary'
                    },
                    buttonsStyling: false
                });

                swalWithBootstrapButtons.fire({
                    title: 'Confirmación',
                    text: "¿ Estas seguro/a de finalizar tu postulación ?",
                    icon: 'question',
                    showCancelButton: true,
                    /* confirmButtonColor: '#198754',
                    cancelButtonColor: '#d33', */
                    confirmButtonText: 'Sí! Enviar',
                    cancelButtonText : 'Cancelar'
                    }).then((result) => {
                    if (result.isConfirmed) {

                        currentObj.isLoading = true;

                        axios.post( api_url +  "/api/postula/enviarPostulacion", 
                        params
                        ,	
                        {headers}			
                        )
                        .then(function (response) {

                            currentObj.isLoading = false;

                            if( response.data.errors ){
                                
                                swalWithBootstrapButtons.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.data.errors,
                                
                                })
                            
                            }else{

                                swalWithBootstrapButtons.fire({
                                    icon: 'success',
                                    title: 'Excelente',
                                    text: 'Has enviado y finalizado tu proceso de postulación. Pronto te entregaremos los resultados de tu postulación. ',
                                
                                })

                                currentObj.postulacion = response.data.repostulacion;
                                currentObj.persona     = response.data.persona;

                                currentObj.page = 3;
                            }
                            

                        })
                        .catch(function (error) {
                            currentObj.isLoading = false;
                            alert( "error en la operación : " + error  );
                        });
                        
                    }

                })

            },


            fetchListaCarrera(){
                
                var credentials = window.btoa("visitor:NRKmM2SDf0");

                var currentObj = this;

                currentObj.isLoading = true;
   
                axios({

                    url: api_url + '/api/admin/carrera',
                    method: 'GET',
                    'X-Requested-With' : 'XMLHttpRequest',
                    responseType: 'json', // important
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${credentials}`,
                        'Access-Control-Allow-Origin': '*',
                        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Content-Type, authentification, authorization"
                        
                      
                    },
                }
                
                ).then((response) => {

                    this.carreras =  response.data.data/* .filter(el => (el.habilita_repostulacion_pace == 1 && el.habilita_repostulacion == 1) ) */; 
                    
                    this.carrerasPace = this.carreras.filter(el => (el.habilita_repostulacion_pace == 1 ) ); 
                    this.carrerasReg = this.carreras.filter(el => el.habilita_repostulacion == 1  ); 

                    this.filtrarCarrera();

                    currentObj.isLoading = false
                });


            } ,

            filtrarCarrera(){

                //var currentObj = this;
                if( this.postulacion.via_ingreso == 'P' ){

                    this.carreras = this.carrerasPace; 

                }else{

                    this.carreras = this.carrerasReg; 
                }
            },

            fetchPostulacion(){

                var credentials = window.btoa("visitor:NRKmM2SDf0");

                return  fetch( api_url + "/api/postula/obtenerDatosPostulacion?" + new URLSearchParams({
                            
                            username: this.$props.username ,
                            documento : this.$props.documento
                            
                        }), {
                            method: 'get',
                            headers: {
                            'content-type': 'application/json',
                            'Authorization' : `Basic ${credentials}`,
                            'Access-Control-Allow-Origin': '*',
                            }
                        }).then((response) => response.json())
                .then((responseData) => {
                
                if(responseData.status == 'true' ){// En caso de q hay información guardada pendiente

                    
                    this.carrera =  responseData.carrera;
                    this.postulacion = responseData.postulacion;

                    this.filtrarCarrera(this.postulacion.via_ingreso);

                    if( this.postulacion.estado == 'P' ){   

                        this.persona =  responseData.persona
                        this.simular();

                    }

                    if(this.postulacion.estado == 'A' || this.postulacion.estado == 'R' || this.postulacion.estado == 'G'){

                        this.persona =  responseData.persona;
                        this.simulacion = responseData.simulacion;
                        this.simulacion.carrera = responseData.datosCarrera

                    }
        
                }else{
                    this.postulacion.via_ingreso =  ( this.$props.pace == 'PACE') ? 'P' : 'R';
                    this.filtrarCarrera(this.postulacion.via_ingreso);
                    
                }

                })
                .catch(error => console.warn(error));

                


            } ,
           

            fetchPais(){
            
                var credentials = window.btoa("visitor:NRKmM2SDf0");
                const currentObj = this;

                return  fetch( api_url + '/api/common/getPaises',{
                                method: 'get',
                                headers: {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers": "Content-Type, authentification, authorization"
                                }
                            }).then((response) => response.json())
                .then((responseData) => {
                

                currentObj.paises = responseData.data;  

                
                })
                .catch(error => console.warn(error));


            } ,

            fetchRegion(){
              
                var credentials = window.btoa("visitor:NRKmM2SDf0");
                const currentObj = this;

                return  fetch( api_url + '/api/common/getregiones',{
                                method: 'get',
                                headers: {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                                "Access-Control-Allow-Headers": "Content-Type, authentification, authorization"
                                }
                            }).then((response) => response.json())
                .then((responseData) => {
                

                currentObj.regiones = responseData.regiones;  

                })
                .catch(error => console.warn(error));


            } ,

            fetchProvincia(){
                
                var credentials = window.btoa("visitor:NRKmM2SDf0");
                const currentObj = this;

                return  fetch( api_url + '/api/common/getprovincias?' + new URLSearchParams({
                                
                                region: this.persona.codigo_region_d ,
                                
                            }),{
                                method: 'get',
                                headers: {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                }
                            }).then((response) => response.json())
                .then((responseData) => {

                    currentObj.provincias = responseData.provincias;
                    currentObj.comunas = [];
                

                
                })
                .catch(error => console.warn(error));

                
            },

            fetchComuna(){

                var credentials = window.btoa("visitor:NRKmM2SDf0");
                const currentObj = this;

                return  fetch( api_url + '/api/common/getcomunas?' + new URLSearchParams({
                                
                                provincia: this.persona.codigo_provincia_d ,
                                
                            }),{
                                method: 'get',
                                headers: {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                }
                            }).then((response) => response.json())
                .then((responseData) => {

                    currentObj.comunas = responseData.comunas;
                   
                })
                .catch(error => console.warn(error));

                
            },


            formatDate: function 
            ( str ){

                let date = new Date(str);
                
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();

                return `${day}/${month}/${year}`;

            }

        },

        async created(){
            
            this.isLoading = true;
            

            await Promise.allSettled([ this.fetchPostulacion() , this.fetchListaCarrera()]);

            

            if( this.postulacion.estado == 'A' || this.postulacion.estado == 'R' || this.postulacion.estado == 'G' ){

                this.page = 3;

                this.isLoading = false;

            }else{

                //this.postulacion.via_ingreso = (this.$props.pace === 'PACE') ? 'P' : 'R';

                if( this.persona.codigo_region_d != null ){
                    await this.fetchProvincia();
                    await this.fetchComuna();
                    
                }
                await this.fetchPais();

                this.isLoading = false;

            }
            //console.log( this.$props.pace		);

        },


        mounted() {

            
        }
    }

    

</script>




