<template>

    <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"/>

    <div class="row justify-content-center" >

        <div class="col-md-12">

            <div class="card">
                <div class="card-header">
                    <h6>Postulaciones Recibidas por Carrera</h6>
                </div>
                <div class="card-body">
                    <div class="row">

                        <div class="col-md-8">
                            <span>Carrera:</span>
                            <!-- <p style="white-space: pre-line;">{{ message }}</p> -->
                            <br>
                            <select
                                id="carrera"
                                v-model="carrera"
                                name="carrera"
                                placeholder="Seleccione"
                                v-on:change="buscaRepostulantes()"
                                class="form-control"
                                >
                                <!-- <option value = "0">Todas las carreras </option> -->
                                <option v-for="option in carreras" v-bind:value="option.cod_demre">
                                {{option.cod_demre}} - {{ option.carrera }} <!-- <p v-bind:style="{ color: 'red'}" v-if="option.habilita_repostulacion == 1">(Abierta)</p> -->
                                </option>
                            </select>

                            <!-- <p style="white-space: pre-line; color: crimson;">{{ error_simulacion }}</p> -->

                        </div>

                        <div class="col-md-2">
                            <span>Cantidad Registros:</span>
                            <br>
                            <input id="cant" class="form-control" v-model="cant" type="number" v-on:change="buscaRepostulantes()" />
                        </div>

                    </div>

                    <div class="row mt-3">
                        <div class="col-md-3">
                            <button class="btn btn-success btn-sm" v-on:click="exportarAdmision()"><font-awesome-icon :icon="['fas', 'file-excel']" class="icon alt"/> Admisión</button>&nbsp;&nbsp;
                            <!-- <button class="btn btn-danger btn-sm" v-on:click="exportarSegic()"><font-awesome-icon :icon="['fas', 'file-excel']" class="icon alt"/> SEGIC&nbsp;&nbsp;</button> -->
                        </div>
                       

                    </div>
                    
                </div>


            </div>
        </div>

    </div>

    <EasyDataTable
        :headers="headers"
        :items="postulaciones"
        
        rows-per-page-message="Filas por página"
        empty-message="No existen elementos para mostrar"
        rows-of-page-separator-message="de"
        buttons-pagination
    >

        <template #item-ndoc="item">
            <label>{{item.ndoc}}-{{item.dvdoc}}</label>
        </template>
        <template #item-nombres="item" >
            <label>{{item.primer_apellido.toUpperCase()}} {{ ( !!item.segundo_apellido) ? item.segundo_apellido.toUpperCase() : ''  }} {{item.nombres.toUpperCase()}} </label>
        </template>
        <template #item-nombre="item" >
            <label> {{item.cod_carrera}} - {{ ( !!item.nombre) ? item.nombre.toUpperCase() : ''}}</label>
        </template>
        <template #item-via_ingreso="item" >
            <!-- <label> {{item.cod_carrera}} - {{ ( !!item.nombre) ? item.nombre.toUpperCase() : ''}}</label> -->
            <label v-if="item.via_ingreso == 'R'" style="text-align: left;">P.A.E.S (Regular)</label>
            <label v-if="item.via_ingreso == 'P'" style="text-align: left;">P.A.C.E</label>
        </template>

        
        <template #item-updated_at="item" >
            <label> {{formatDate(item.updated_at)}}</label>
        </template>


        {{ formatDate(postulacion.updated_at)}}

    </EasyDataTable>


</template>
    

<script>
           
    import EasyDataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    import axios from 'axios'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    import { library } from '@fortawesome/fontawesome-svg-core'

    /* import font awesome icon component */
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
    library.add( faFileExcel )

   
    
    const api_url = process.env.MIX_API_URL;
    const api_externa_url = process.env.MIX_API_EXTERNA_URL;
    
    export default {
        name: 'ListaRepostulacionComponent',
        components:{
            'font-awesome-icon' : FontAwesomeIcon ,
            EasyDataTable , Loading /* , FilenameOption, AutoWidthOption, BookTypeOption */
        },
        data() {
            return {

                isLoading: false,
                fullPage: true,

                carreras : [],
                carrera : null,
                cant: 1000,
                postulaciones: [],
                
                //data  :   [],
                headers: [
                    { text: "RUN/PASAPORTE", value: "ndoc" ,sortable: true } ,
                    { text: "TIPO IDENTIFICACIÓN", value: "tdoc" , sortable: false},
                    { text: "NOMBRE COMPLETO", value: "nombres" , sortable: true},
                    { text: "CARRERA", value: "nombre" , sortable: true},
                    { text: "INGRESO", value: "via_ingreso" , sortable: true},
                    { text: "PTJE. PONDERADO.", value: "ptje_pond" , sortable: true},
                    { text: "FECHA" , value: "updated_at" , sortable: false },
                    { text: "ESTADO", value: "estado", sortable: true},
                    
                   
                ],
                isActive : false, 

              
            }
        },
        methods: {


            fetchListaCarrera(){
                
                //var credentials = window.btoa("api:C51CDwaa8lBp");
                var credentials = window.btoa("visitor:NRKmM2SDf0");
                this.isLoading = true;

                    return   fetch( api_url + '/api/admin/carrera', {
                                method: 'get',
                                headers: {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                }
                            }).then((response) => response.json())
                .then((responseData) => {

                    this.carreras = responseData.data;

                    var opcionTodos = {
                        cod_demre : 0 ,
                        carrera      : 'Todas las carreras',
                        habilita_repostulacion : 1,
                        habilita_repostulacion_pace : 1
                    };

                    this.carreras.unshift(opcionTodos);


                    this.isLoading = false;
            

                })
                .catch(error => { console.warn(error); this.isLoading = false; });

                


            } ,

            buscaRepostulantes(){

                var credentials = window.btoa("visitor:NRKmM2SDf0");
                this.isLoading = true;

                    return  fetch(   api_url +  "/api/postula/obtenerListaPostulacionesCarrera?" + new URLSearchParams({
                                
                                carrera: this.carrera ,
                                cantidad : this.cant
                                
                            }), {
                                method: 'get',
                                headers: {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                }
                            }).then((response) => response.json())
                .then((responseData) => {
                
                if(responseData.status == 'true' ){

                    
                    this.postulaciones =  responseData.postulaciones;
                    
        
                }
                this.isLoading = false;

                })
                .catch(error => {console.warn(error) ; this.isLoading = false; }   );

                


            },
            exportarAdmision(){

              var credentials = window.btoa("visitor:NRKmM2SDf0");
              let currentObj = this;

              currentObj.isLoading = true;

                axios({
                    url: api_url +'/api/postula/descargarListaPostulacionesCarrera', //your url
                    method: 'GET',
                    responseType: 'blob', // important
                    params: { carrera: this.carrera  , cantidad: this.cant  } ,
                    headers: {
                        'content-type': 'application/json',
                        'Authorization' : `Basic ${credentials}`,
                        'Access-Control-Allow-Origin': '*',
                    },
                }
                
                ).then((response) => {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'reporte-repostulantes-admision-' + currentObj.carrera +'.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    currentObj.isLoading = false
                });
          

                
              
            },
            formatDate: function 
            ( str ){

                let date = new Date(str);
                
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();

                let minutes = date.getMinutes();
                let hour = date.getHours();

                return `${day}/${month}/${year} ${hour}:${minutes}`;

            }              



            

        },

        async created(){
            
            
            await this.fetchListaCarrera();
            


        },


        mounted() {


        
        }
    }

    

</script>



