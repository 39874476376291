<template>

    <EasyDataTable
        :headers="headers"
        :items="data"
        
        rows-per-page-message="Filas por página"
        empty-message="No existen elementos para mostrar"
        rows-of-page-separator-message="de"
        buttons-pagination
    >

        <template #item-habilita_repostulacion="item" >

            <div class='form-check form-switch' style="align-items: center;">
                <input
                    class='form-check-input' 
                    type='checkbox'
                    v-bind='$attrs'
                    :checked= item.habilita_repostulacion
                    @change='changeEstadoHab(  item.id , $event.target.checked , "R")'>
                <label
                    class='form-check-label'
                    >
                    <!-- <slot/> -->
                </label>
            </div>
            
        </template>

        <template #item-habilita_repostulacion_pace="item" >

            <div class='form-check form-switch' style="align-items: center;">
                <input
                    class='form-check-input' 
                    type='checkbox'
                    v-bind='$attrs'
                    :checked= item.habilita_repostulacion_pace
                    @change='changeEstadoHab(  item.id , $event.target.checked , "P")'>
                <label
                    class='form-check-label'
                    >
                    <!-- <slot/> -->
                </label>
            </div>

        </template>

    </EasyDataTable>


</template>
    

<script>
           
    import EasyDataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    import axios from 'axios'

    const api_url = process.env.MIX_API_URL;
    
    export default {
        name: 'ListaCarreraComponent',
        components:{
            
            EasyDataTable
        },
        data() {
            return {
                
                data  :   [],
                headers: [
                    { text: "COD. CARRERA", value: "cod_demre" ,sortable: true} ,
                    { text: "COD. USACH", value: "cod_usach" , sortable: true},
                    { text: "NOMBRE", value: "carrera" , sortable: true},
                    { text: "PJE. POND. MIN.", value: "ponderado_minimo" , sortable: true},
                    { text: "PJE. PROM. MIN.", value: "promedio_minimo" , sortable: true},
                    //{ text: "PJE. ULT MATRIC", value: "ptj_ult_matri", sortable: true},
                    { text: "HAB. REPOSTULACIÓN", value: "habilita_repostulacion", sortable: true  },
                    { text: "HAB. REPOSTULACIÓN PACE", value: "habilita_repostulacion_pace", sortable: true  },
                   
                ],
                isActive : false
            }
        },
        methods: {

            fetchData(){
               
                var credentials = window.btoa("visitor:NRKmM2SDf0");
                

                axios({
                    url: api_url + '/api/admin/carrera', //your url
                    method: 'GET',
                    'X-Requested-With' : 'XMLHttpRequest',
                    responseType: 'json', // important
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${credentials}`,
                        'Access-Control-Allow-Origin': '*',
                        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Content-Type, authentification, authorization"
                        

                        //"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
                    },
                }
                
                ).then((response) => {

                    console.log( response );
                    this.data =  response.data.data; 

                    //currentObj.isLoading = false
                });


            } ,

            changeEstadoHab(  id , val , via){
                
                const found = this.data.find(element => element.id == id);
                var credentials = window.btoa("visitor:NRKmM2SDf0");

                const headers = {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                }
               
                axios
                .put( api_url +  "/api/admin/actualizaHabCarrera", 
                
                {
                    habilita: (!!val == true) ? 1 : 0	,
                    id:id	,
                    via:via
                },	
                {headers}			
                )
                .then(function (response) {
                    if( via == 'R')  found.habilita_repostulacion      = (val == true) ? 1 : 0
                    if( via == 'P')  found.habilita_repostulacion_pace = (val == true) ? 1 : 0

                    /* const index = arr.findIndex(object => {
                     return object.id === id;
                    }); */
                    
                    //alert( found.id );
        
                })
                .catch(function (error) {
                    alert( "error en la operación : " + error  );
                });
                

            }



        },

        async created(){
            
            this.isActive = true
            await this.fetchData()
            this.isActive = false


        },


        mounted() {


        
        }
    }

    

</script>



