<template>

    <EasyDataTable
        :headers="headers"
        :items="data"
        
        rows-per-page-message="Filas por página"
        empty-message="No existen elementos para mostrar"
        rows-of-page-separator-message="de"
        buttons-pagination
    >

        <template #item-updated_at="item" >
            <label> {{formatDate(item.updated_at)}}</label>
        </template>

        <template #item-is_admin="item" >

            <div class='form-check form-switch' style="align-items: center;">
                <input
                    class='form-check-input' 
                    type='checkbox'
                    v-bind='$attrs'
                    :checked= item.is_admin
                    @change='changeEstadoHab(  item.name , $event.target.checked)'>
                <label
                    class='form-check-label'
                    >
                    <!-- <slot/> -->
                </label>
            </div>
            
        </template>

    </EasyDataTable>


</template>
    

<script>
           
    import EasyDataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    import axios from 'axios'

    const api_url = process.env.MIX_API_URL;
    
    export default {
        name: 'ListaUsuariosComponent',
        components:{
            
            EasyDataTable
        },
        data() {
            return {
                
                data  :   [],
                headers: [
                    { text: "ID DE USUARIO", value: "name" ,sortable: true} ,
                    { text: "FECHA ACTUALIZACIÓN", value: "updated_at" , sortable: true},
                    
                    { text: "HABILITADO", value: "is_admin", sortable: true  },
                   
                ],
                isActive : false
            }
        },
        methods: {

            fetchData(){
              
                var credentials = window.btoa("visitor:NRKmM2SDf0");
                
                axios({
                    url: api_url + "/api/admin/obtenerListaUsuarios", //your url
                    method: 'GET',
                    'X-Requested-With' : 'XMLHttpRequest',
                    responseType: 'json', // important
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : `Basic ${credentials}`,
                        'Access-Control-Allow-Origin': '*',
                        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Content-Type, authentification, authorization"
                        

                    },
                }
                
                ).then((response) => {

                    console.log( response );
                    this.data =  response.data.data; 

                    
                });


            } ,

            changeEstadoHab(  id , val ){
              
                const found = this.data.find(element => element.name == id);
               
                var credentials = window.btoa("visitor:NRKmM2SDf0");

                const headers = {
                                'content-type': 'application/json',
                                'Authorization' : `Basic ${credentials}`,
                                'Access-Control-Allow-Origin': '*',
                                }
               
                axios
                .post(  api_url + "/api/admin/actualizaHabUsuario"  , 
                
                {
                    is_admin: (val) ? 1 : 0	,
                    name	: id		
                },	
                {headers}			
                )
                .then(function (response) {
                    found.is_admin = response.data.data.is_admin;
                   
                    console.log( "ok ");
        
                })
                .catch(function (error) {
                    alert( "error en la operación : " + error  );
                });
                

            },

            formatDate: function 
            ( str ){

                let date = new Date(str);
                
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                let hour = date.getHours();
                let minutes = date.getMinutes();

                return `${day}/${month}/${year} ${hour}:${minutes}`;

            }      

        },

        async created(){
            
            this.isActive = true
            await this.fetchData()
            this.isActive = false


        },


        mounted() {


        
        }
    }

    

</script>



