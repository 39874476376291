<template>

    <simulacion-postulante-component :username="username" :documento="documento" :pace="pace" />
   
</template>
    

<script>
           
    import axios from 'axios'
    import SimulacionPostulanteComponent from "./SimulacionPostulanteComponent.vue";

   
    export default {
        name: 'FormularioPostulanteComponent',
        components:{
            SimulacionPostulanteComponent
            
        },
        props: {
            
            username: String,
            documento: String,
            pace: String
            
        },

        data() {
            return {

            }
        },
        methods: {



        },

        async created(){
            
        
        },


        mounted() {


        
        }
    }

    

</script>



